<template>
	<div class="wrapper">
		<div class="searchbox">
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-form-item label="商品编号">
					<el-input v-model="formInline.sn" clearable placeholder="请输入商品编号"></el-input>
				</el-form-item>
				<el-form-item label="商品名称">
					<el-input v-model="formInline.name" clearable placeholder="请输入商品名称"></el-input>
				</el-form-item>
				<el-form-item label="所属商户">
					<el-select v-model="formInline.shopId" filterable clearable placeholder="请选择所属商户">
						<el-option v-for="(item,index) in shopList" :key="index" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="onSubmit">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div>
			<el-table :data="tableData" border style="width: 100%">
				<el-table-column label="序号" type="index" :index="indexMethod" align="center">
				</el-table-column>
				<el-table-column prop="sn" label="商品编号" align="center">
				</el-table-column>
				<el-table-column prop="name" label="名称" align="center">
				</el-table-column>
				<el-table-column prop="shopname" label="所属商户" align="center">
				</el-table-column>
				<el-table-column prop="category_name" label="所属类目" align="center">
				</el-table-column>
				<el-table-column label="图片" align="center">
					<template slot-scope="scope">
						<div class="imgbox_table" :style="{ 'background-image' : `url(${scope.row.pic_url})`}"></div>
					</template>
				</el-table-column>
				<el-table-column label="详情" align="center">
					<template slot-scope="scope">
						<el-button @click="viewRow(scope.row)" type="text" icon="el-icon-view"
							size="mini">查看</el-button>
					</template>
				</el-table-column>
				<el-table-column prop="counter_price" label="市场售价" align="center">
				</el-table-column>
				<el-table-column prop="retail_price" label="消费价格" align="center">
				</el-table-column>
				<el-table-column label="库存" align="center">
					<template slot-scope="scope">
						<span>{{scope.row.goods[0].number}}</span>
					</template>
				</el-table-column>
				<el-table-column label="是否热品" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.is_hot === true">是</span>
						<span v-else>否</span>
					</template>
				</el-table-column>
				<el-table-column label="是否在售" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.is_new === true">是</span>
						<span v-else>否</span>
					</template>
				</el-table-column>
				<el-table-column prop="sort" label="排序" align="center">
				</el-table-column>
				<el-table-column label="审核状态" align="center">
					<template slot-scope="scope">
						<span v-if="scope.row.checkflag == 0">未提交</span>
						<span v-if="scope.row.checkflag == 1">待审核</span>
						<span v-if="scope.row.checkflag == 2">审核通过</span>
						<span v-if="scope.row.checkflag == 3">审核不通过</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="150" align="center">
					<template slot-scope="scope">
						<el-button @click="shenhe(scope.row)" type="primary"
							size="mini">审核</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<div class="pagebox">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="totalRow">
			</el-pagination>
		</div>
		<el-dialog class="dialogForm" title="审核" :close-on-click-modal="false" :visible.sync="shenheVisible"
			width="900px" :before-close="beforeClose2">
			<el-form :model="form2" :rules="rules2" ref="form2" label-width="150px" style="width: 94%;">
				<el-form-item label="审核状态" prop="checkflag">
					<el-select v-model="form2.checkflag" filterable clearable placeholder="请选择审核状态">
						<el-option label="审核通过" value="2">
						</el-option>
						<el-option label="审核不通过" value="3">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="审核意见" prop="checkmsg">
					<el-input v-model="form2.checkmsg" type="textarea" :rows="2" clearable placeholder="请输入审核意见"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="submitForm2('form2')">确定提交</el-button>
			</div>
		</el-dialog>
		<el-dialog class="dialogForm" :title="title" :close-on-click-modal="false" :visible.sync="dialogFormVisible"
			width="900px" :before-close="beforeClose">
			<el-form :model="form" :rules="rules" ref="form" label-width="150px" style="width: 94%;"
				:disabled="viewFlag">
				<el-form-item label="商品编号" prop="sn">
					<el-input v-model="form.sn" clearable placeholder="请输入商品编号"></el-input>
				</el-form-item>
				<el-form-item label="商品名称" prop="name">
					<el-input v-model="form.name" clearable placeholder="请输入商品名称"></el-input>
				</el-form-item>
				<el-form-item label="商品关键字" prop="keywords">
					<el-input v-model="form.keywords" clearable placeholder="请输入商品关键字（以；号隔开）"></el-input>
				</el-form-item>
				<el-form-item label="商品简介" prop="intro">
					<el-input v-model="form.intro" clearable placeholder="请输入商品简介"></el-input>
				</el-form-item>
				<el-form-item label="所属类目" prop="category_id">
					<el-select v-model="categoryTypeCheckedData" clearable placeholder="请选择所属类目"
						:popper-append-to-body="false">
						<el-option :label="categoryTypeCheckedData" :value="form.category_id" style="height: auto;">
							<el-tree :data="categoryTypeData" :load="loadCategoryNode" lazy node-key="id" ref="tree"
								highlight-current :props="categoryTypeProps" @current-change="handleNodeCategoryClick">
							</el-tree>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="所属商户" prop="shop_id">
					<el-select v-model="form.shop_id" filterable clearable placeholder="请选择所属商户">
						<el-option v-for="(item,index) in shopList" :key="index" :label="item.name" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="是否新品" prop="is_new">
					<el-radio-group v-model="form.is_new">
						<el-radio :label="false">非新品</el-radio>
						<el-radio :label="true">新品</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="是否热卖" prop="is_hot">
					<el-radio-group v-model="form.is_hot">
						<el-radio :label="false">普通</el-radio>
						<el-radio :label="true">热卖</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="是否在售" prop="is_on_sale">
					<el-radio-group v-model="form.is_on_sale">
						<el-radio :label="false">未售</el-radio>
						<el-radio :label="true">在售</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="商品排序" prop="sort">
					<el-input v-model="form.sort" maxlength="4" clearable placeholder="请输入商品排序（越大越靠前）"
						oninput="value=value.replace(/^\D*(\d*(?:)?).*$/g, '$1')"></el-input>
				</el-form-item>
				<el-form-item label="市场价格" prop="counter_price">
					<el-input v-model="form.counter_price" clearable placeholder="请输入市场价格"
						oninput="value=value.replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3')">
						<i slot="suffix" style="font-style: normal; margin-right: 10px; color: #333;">元</i>
					</el-input>
				</el-form-item>
				<el-form-item label="消费价格" prop="retail_price">
					<el-input v-model="form.retail_price" clearable placeholder="请输入消费所需积分"
						oninput="value=value.replace(/^\D*(\d*(?:)?).*$/g, '$1')">
						<i slot="suffix" style="font-style: normal; margin-right: 10px; color: #333;">积分</i>
					</el-input>
				</el-form-item>
				<!-- <el-form-item label="消费积分类型" prop="payment_method">
          <el-checkbox-group v-model="form.payment_method" @change="pointtypechange">
            <el-checkbox v-for="item in payTypes" :label="item.value" :key="item.label">{{item.label}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item> -->
				<el-form-item label="商品类型" prop="type">
					<el-select style="width: 100%" v-model="form.type" clearable placeholder="请选择商品类型">
						<el-option label="实物商品" :value="0"></el-option>
						<el-option label="虚拟商品" :value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="核销类型" prop="verification_type" v-if="form.type == 1">
					<el-select style="width: 100%" v-model="form.verification_type" clearable placeholder="请选择核销类型">
						<el-option label="手动核销" :value="0"></el-option>
						<el-option label="自动核销" :value="1"></el-option>
					</el-select>
				</el-form-item>

				<el-form-item label="商品单位" prop="unit">
					<el-input v-model="form.unit" clearable placeholder="请输入商品单位"></el-input>
				</el-form-item>
				<el-form-item label="商品库存" prop="stockNum">
					<el-input v-model="form.stockNum" @input='changeInput' clearable placeholder="请输入商品库存"
						oninput="value=value.replace(/^\D*(\d*(?:)?).*$/g, '$1')"></el-input>
				</el-form-item>
				<el-form-item label="商品详情" prop="detail">
					<vue-editor v-model="form.detail" id="container" class="editor-container" ref="quillEditor"
						@image-added="handleImageAdded" useCustomImageHandler :customModules="customModulesForEditor"
						:editorOptions="editorSettings"></vue-editor>
				</el-form-item>
				<el-form-item label="商品照片" prop="pic_url">
					<el-upload action="#" :class="{goodsfacehide: goodsfacehideUploadEidt}" :auto-upload="false"
						accept="image/*" :show-file-list="true" list-type="picture-card" :on-change="goodsfaceImgchange"
						:on-progress="imgloading" :file-list="goodsfaceList">
						<div slot="file" slot-scope="{file}">
							<img :src="file.url" style="width:100%;height:146px !important">
							<span class="el-upload-list__item-actions">
								<span class="el-upload-list__item-preview" @click="bigImg(file)"><i
										class="el-icon-zoom-in"></i></span>
								<span class="el-upload-list__item-delete" @click="goodsfaceImgRemove(file)"
									v-show="!viewFlag">
									<i class="el-icon-delete"></i>
								</span>
							</span>
						</div>
						<i class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>
				<el-form-item label="商品画廊" prop="gallery">
					<el-upload action="#" :auto-upload="false" accept="image/*" :class="{ hualanghide: viewFlag}"
						:show-file-list="true" list-type="picture-card" :on-change="galleryChange"
						:on-progress="imgloading" :file-list="galleryList">
						<div slot="file" slot-scope="{file}">
							<img :src="file.url" style="width:100%;height:146px !important">
							<span class="el-upload-list__item-actions">
								<span class="el-upload-list__item-preview" @click="bigImg(file)"><i
										class="el-icon-zoom-in"></i></span>
								<span class="el-upload-list__item-delete" @click="galleryRemove(file)"
									v-show="!viewFlag">
									<i class="el-icon-delete"></i>
								</span>
							</span>
						</div>
						<i class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer" v-show="!viewFlag">
				<el-button v-show="editType === 'add'" @click="resetForm('form')">重置</el-button>
				<el-button type="primary" @click="submitForm('form')">确定提交</el-button>
			</div>
		</el-dialog>
		<!-- 图片大图预览 -->
		<el-dialog :visible.sync="imgDialogVisible" title="图片预览" append-to-body>
			<img width="100%" :src="dialogImageUrl" alt="">
		</el-dialog>



		


	</div>
</template>

<script>
	import {
		VueEditor
	} from 'vue2-editor'
	// import { ImageDrop } from "quill-image-drop-module";
	import ImageResize from "quill-image-resize-module";
	import {
		goodsList,
		goodsDelete,
		returnOfGoodsFn,
		goodsCreate,
		goodsUpdate,
		goodsDetail,
		checklist
	} from '@/api/goodsManage'
	import {
		queryCategory,
		upLoadFileObj
	} from '@/api/itemCategory'
	import {
		getShopsPage,
		getDictItem
	} from '@/api/merchantManage'
	import {
		sureMessageBox
	} from '@/utils/common'
	import {
		getCodePage,
		bingProduct
	} from '@/api/redeemCode'
	export default {
		name: "",
		components: {
			VueEditor,
		},
		props: {},
		data() {
			return {
				formInline: {
					sn: '',
					name: '',
					shopId: '',
					checkflag:''
				},
				productId: '',
				currentPage: 1,
				totalRow: 0,
				pageSize: 10,
				tableData: [],
				title: '',
				dialogFormVisible: false,
				viewFlag: false, //是否为查看
				editType: '', //新增还是编辑
				form: {
					sn: '',
					name: '',
					keywords: '',
					intro: '',
					category_id: '',
					shop_id: '',
					is_new: '',
					is_hot: '',
					is_on_sale: '',
					sort: '',
					counter_price: '',
					retail_price: '',
					payment_method: ['1'],
					stockNum: null,
					unit: '',
					detail: '',
					pic_url: '',
					gallery: '',
					verification_type: 0,
					type: 0

				},
				form2:{
					checkmsg:'',
					checkflag:'',
					id:''
				},
				shenheVisible:false,
				rules2:{
					checkflag: [{
						required: true,
						message: '请选择审核状态',
						trigger: 'blur'
					}],
					checkmsg: [{
						required: true,
						message: '请输入审核意见',
						trigger: 'blur'
					}],
				},
				rules: {
					sn: [{
						required: true,
						message: '请输入商品编号',
						trigger: 'blur'
					}],
					name: [{
						required: true,
						message: '请输入商品名称',
						trigger: 'blur'
					}],
					keywords: [{
						required: true,
						message: '请输入商品关键字',
						trigger: 'blur'
					}],
					intro: [{
						required: true,
						message: '请输入商品详情',
						trigger: 'blur'
					}],
					category_id: [{
						required: true,
						message: '请选择所属类目',
						trigger: 'change'
					}],
					shop_id: [{
						required: true,
						message: '请选择所属商户',
						trigger: 'change'
					}],
					is_new: [{
						required: true,
						message: '请选择是否新品',
						trigger: 'change'
					}],
					is_hot: [{
						required: true,
						message: '请选择是否热卖',
						trigger: 'change'
					}],
					is_on_sale: [{
						required: true,
						message: '请选择是否在售',
						trigger: 'change'
					}],
					sort: [{
						required: true,
						message: '请输入商品排序',
						trigger: 'blur'
					}],
					counter_price: [{
						required: true,
						message: '请输入市场价格',
						trigger: 'blur'
					}],
					retail_price: [{
						required: true,
						message: '请输入消费所需积分',
						trigger: 'blur'
					}],
					payment_method: [{
						required: true,
						message: '请选择消费积分类型',
						trigger: 'change'
					}],
					stockNum: [{
						required: true,
						message: '请输入商品库存',
						trigger: 'blur'
					}],
					unit: [{
						required: true,
						message: '请输入商品单位',
						trigger: 'blur'
					}],
					detail: [{
						required: true,
						message: '请输入商品简介',
						trigger: 'change'
					}],
					pic_url: [{
						required: true,
						message: '请上传商品照片',
						trigger: 'change'
					}],
					gallery: [{
						required: true,
						message: '请上传商品画廊照片',
						trigger: 'change'
					}],
					type: [{
						required: true,
						message: '请选择商品类型',
						trigger: 'change'
					}],
					verification_type: [{
						required: true,
						message: '请选择核销类型',
						trigger: 'change'
					}],
				},
				categoryTypeData: [],
				categoryTypeProps: {
					label: 'name',
					children: 'children',
					id: 'id',
				},
				categoryTypeCheckedData: '',
				shopList: [],
				//积分类型
				pointTypes: [],
				goodsfacehideUploadEidt: false,
				goodsfaceList: [],
				galleryList: [],
				imgDialogVisible: false,
				dialogImageUrl: '',
				goods: [],
				// 调整图片大小和位置
				customModulesForEditor: [{
					alias: "imageResize",
					module: ImageResize
				}],
				// 设置编辑器图片可拖拽
				editorSettings: {
					modules: {
						imageResize: {}
					}
				},
				payTypes: [],
				linkCodeDialogVisible: false,
				codesTableData: [],
				multipleSelection: [],
				choosedCodes: [],
				codesList: [],
				linkCodeRow: {}
			}
		},
		watch: {},
		created() {},
		mounted() {
			this.getList();
			this.getCategory();
			this.getShopList();
			this.getPayType();
		},
		computed: {},
		methods: {
			submitForm2(formName) {
				let that = this;
				// this.$forceUpdate();
				that.$refs[formName].validate((valid) => {
					if (valid) {
						let a = new Date();
						let year = a.getFullYear()
						let mon = a.getMonth() + 1
						let month = mon > 9 ? mon : '0' + mon
						let day = a.getDate() > 9 ? a.getDate() : '0' + a.getDate()
						let hour = a.getHours() > 9 ? a.getHours() : '0' + a.getHours()
						let min = a.getMinutes() > 9 ? a.getMinutes() : '0' + a.getMinutes()
						let s = a.getSeconds() > 9 ? a.getSeconds() : '0' + a.getSeconds()
						let time =  year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + s;
						let tip = '是否确认审核？';
						let product = {};
						let goods = [];
						goodsDetail({
							productId: that.form2.id
						}).then(res => {
							if (res.result == 200) {
								product = res.detail.product;
								goods = res.detail.goods;
								product.checkflag = that.form2.checkflag;
								product.checktime = time;
								product.checkman = window.localStorage.getItem('userid');
								product.checkmsg = that.form2.checkmsg;
								sureMessageBox(tip).then(res => {
									goodsUpdate({
										"product": product,
										"goods": goods,
									}).then(res => {
										if (res.result == 200) {
											this.$message.success('操作成功')
											this.shenheVisible = false;
											this.form2 = {
												id:'',
												checkflag:'',
												checkmsg:''
											}
											this.getList();
										} else {
											this.$message.warning(res.description)
										}
									})
								}).catch(err => {
									console.log(err)
								})
							}
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			//提交关联兑换码
			sureBind() {
				if (this.codesList.length < this.linkCodeRow.goods[0].number) {
					this.$message.warning('兑换码数量不能少于库存数，请先关联兑换码')
					return false;
				}
				sureMessageBox('是否确认提交？').then(res => {
					//状态为未使用，才能提交关联
					let codeIds = [];
					this.codesList.map(item => {
						// if(item.status == 1){
						codeIds.push(item.id)
						// }
					})
					bingProduct({
						productId: this.linkCodeRow.goods[0].product_id,
						goodsId: this.linkCodeRow.goods[0].id,
						codeIds: codeIds.join(',')
					}).then(res => {
						if (res.result == 200) {
							this.$message.success('操作成功');
							this.linkCodeDialogVisible = false;
							this.getList();
						} else {
							this.$message.warning(res.description)
						}
					})
				}).catch(err => {
					console.log(err)
				})

			},
			//移除已选兑换码
			deleteChooseCode(index, row, rows) {
				//如果移除原已选兑换码，删除原兑换码即可
				let rowIdx = this.choosedCodes.indexOf(row);
				if (rowIdx !== -1) {
					this.choosedCodes.splice(rowIdx, 1);
					this.dealCodes();
				}

				//如移除上面选的兑换码，则取消表格的选择
				let rowIdx1 = this.multipleSelection.indexOf(row);
				// console.log('rowIdx1',rowIdx1)
				if (rowIdx1 !== -1) {
					let rowIdx2 = this.codesTableData.indexOf(row);
					this.$refs.multipleTable.toggleRowSelection(this.codesTableData[rowIdx2], false)
					this.dealCodes();
				}

				let hasIndex = this.codesTableData.indexOf(row);
				if (hasIndex == -1) {
					this.codesTableData.push(row);
				}


			},
			//选择兑换码
			handleSelectionChange(val) {
				// console.log(val)
				this.multipleSelection = val;
				this.dealCodes();
			},
			//兑换码处理，相同的合并
			dealCodes() {
				this.codesList = [];
				let arr = [...this.multipleSelection];
				let arr1 = [...this.choosedCodes];
				this.codesList = arr.reduce((pre, cur) => {
					let target = pre.find(ee => ee.id == cur.id)
					if (target) {
						Object.assign(target, cur)
					} else {
						pre.push(cur)
					}
					return pre
				}, arr1)
			},
			//强制刷新payment_method数据
			pointtypechange() {
				// this.$forceUpdate();
			},
			changeInput(i) {
				// this.$forceUpdate();  //强制刷新
			},
			//获取支付方式
			getPayType() {
				getDictItem({
					type: 'payment_method'
				}).then(res => {
					if (res.result == 200) {
						this.$nextTick(() => {
							this.payTypes = res.detail;
						})
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			beforeClose(done) {
				this.resetForm('form');
				done();
			},
			beforeClose2(done) {
				this.form2 = {
					checkflag:'',
					checkmsg:'',
					id:''
				}
				done();
			},
			//获取列表
			getList() {
				checklist({
					name: this.formInline.name,
					sn: this.formInline.sn,
					shopId: this.formInline.shopId,
					pageSize: this.pageSize,
					pageNum: this.currentPage
				}).then(res => {
					// console.log(res)
					if (res.result == 200) {
						let list = res.detail.list;
						this.tableData = list;
						this.totalRow = res.detail.totalRow;
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			//获取商品类目
			getCategory() {
				queryCategory({
					id: 0
				}).then(res => {
					let result = res.detail.children;
					result.forEach(item => {
						if (item.haschildren === 1) {
							item.hasChildren = true
						}
					})
					this.$nextTick(() => {
						this.categoryTypeData = result;
					})
				})
			},
			//获取商品子类目
			loadCategoryNode(node, resolve) {
				/* if(!this.treeIds.includes(tree.id)){
				    this.treeIds.push(tree.id)
				} */
				queryCategory({
					id: node.data.id
				}).then(res => {
					if (res.result == 200) {
						// console.log('子类目', res.detail.children)
						let list = res.detail.children;
						list.forEach(item => {
							if (item.haschildren === 1) {
								item.hasChildren = true
							}
						})
						if (list.length === 0) {
							resolve([])
						} else {
							resolve(list)
						}
					} else {
						resolve([])
						this.$message.warning(res.description)
					}
				})
			},
			handleNodeCategoryClick(data) {
				// console.log("data:",data);
				this.form.category_id = data.id;
				this.categoryTypeCheckedData = data.name;
			},
			setCategoryCheckedKeys(id) {
				// console.log("setCategoryCheckedKeys:",id);
				this.$refs.tree.setCheckedKeys([id]);
			},
			//获取商品列表
			getShopList() {
				getShopsPage({
					name: '',
					phone: '',
					pageSize: 99999,
					pageNum: ''
				}).then(res => {
					// console.log(res)
					if (res.result == 200) {
						this.shopList = res.detail.list;
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			//获取商品详情
			getGoodsDetail(id) {
				goodsDetail({
					productId: id
				}).then(res => {
					if (res.result == 200) {
						let productDes = res.detail.product;
						// let payment_method = res.detail.goods[0].payment_method;
						// productDes.payment_method = payment_method ? payment_method.split(','): [];
						productDes.payment_method = ['1'];
						this.form = productDes;
						// this.form.stockNum = res.detail.goods[0].number;
						this.$set(this.form, 'stockNum', res.detail.goods[0].number);

						let gallery = JSON.parse(this.form.gallery);
						this.galleryList = [];
						gallery.forEach((item, index) => {
							this.galleryList.push({
								name: index,
								url: item
							})
						});

						this.goodsfaceList = [];
						this.goodsfaceList.push({
							name: 'goodsface',
							url: this.form.pic_url
						})
						this.goodsfacehideUploadEidt = true;
						this.setCategoryCheckedKeys(this.form.category_id);
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.currentPage = 1;
				this.getList();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
			//自定义序号
			indexMethod(index) {
				return (this.currentPage - 1) * this.pageSize + (index + 1);
			},
			//查询
			onSubmit() {
				this.currentPage = 1;
				this.getList();
			},
			shenhe(row){
				let that = this;
				that.form2.id = row.id;
				that.shenheVisible = true;
			},
			editRow(row) {
				// console.log("row:",row);
				this.title = '修改商品详情'
				this.viewFlag = false;
				this.editType = 'update';
				this.dialogFormVisible = true;
				this.categoryTypeCheckedData = row.category_name,
					this.getGoodsDetail(row.id);
				this.productId = row.id;
				this.goods = row.goods;
				this.goods[0].price = this.form.retail_price;


				this.linkCodeRow = row;
				//获取未使用的兑换码列表
				getCodePage({
					shopId: row.shop_id,
					productId: this.linkCodeRow.goods[0].product_id,
					goodsId: this.linkCodeRow.goods[0].id,
					status: 2,
					pageSize: 99999,
					page: 1
				}).then(res => {
					// console.log(res)
					if (res.result == 200) {
						this.linkCodeRow.codesNumber = res.detail.list.length;
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			viewRow(row) {
				this.editRow(row);
				this.title = "商品详情";
				this.viewFlag = true;
			},
			submitshenhe(row){
				let that = this;
				let tip = '是否确认提交审核？';
				let product = {};
				let goods = [];
				goodsDetail({
					productId: row.id
				}).then(res => {
					if (res.result == 200) {
						product = res.detail.product;
						goods = res.detail.goods;
						product.checkflag = 1;
						sureMessageBox(tip).then(res => {
							goodsUpdate({
								"product": product,
								"goods": goods,
							}).then(res => {
								if (res.result == 200) {
									this.$message.success('操作成功')
									this.getList();
								} else {
									this.$message.warning(res.description)
								}
							})
						}).catch(err => {
							console.log(err)
						})
					}
				})
			},
			// 上架、下架
			upDownRow(row) {
				let tip = '';
				let activityflag = null;
				if (row.is_on_sale) {
					tip = "下架后未发货订单将会被退回，是否确认下架？"
					activityflag = false;
				} else if (!row.is_on_sale) {
					tip = '是否确认上架'
					activityflag = true;
				}
				let product = {};
				let specifications = [];
				let goods = [];
				goodsDetail({
					productId: row.id
				}).then(res => {
					if (res.result == 200) {
						product = res.detail.product;
						specifications = res.detail.specifications;
						goods = res.detail.goods;
						product.is_new = product.is_new ? 1 : 0;
						product.is_hot = product.is_hot ? 1 : 0;
						product.is_on_sale = activityflag ? 1 : 0;
						sureMessageBox(tip).then(res => {
							goodsUpdate({
								"product": product,
								"goods": goods,
							}).then(res => {
								if (res.result == 200) {
									this.$message.success('操作成功')
									this.getList();
									if (!activityflag) {
										this.returnOfGoods(row);
									}
								} else {
									this.$message.warning(res.description)
								}
							})
						}).catch(err => {
							console.log(err)
						})
					}
				})
			},

			//下架就调退货接口
			returnOfGoods(row) {
				returnOfGoodsFn({
					productId: row.id
				}).then(res => {
					if (res.result == 200) {
						// console.log('退货成功');
					} else {
						this.$message.warning(res.description)
					}
				})
			},
			resetForm(formName) {
				this.form = {
					sn: '',
					name: '',
					keywords: '',
					detail: '',
					category_id: '',
					shop_id: '',
					is_new: '',
					is_hot: '',
					is_on_sale: '',
					sort: '',
					counter_price: '',
					retail_price: '',
					payment_method: ['1'],
					unit: '',
					intro: '',
					pic_url: '',
					gallery: '',
					verification_type: 0,
					type: 0
				}
				if (this.$refs[formName] !== undefined) {
					this.$refs[formName].resetFields();
				}

				this.galleryList = [];
				this.goodsfacehideUploadEidt = false;
				this.goodsfaceList = [];
				this.categoryTypeCheckedData = '';
			},
			//图片预览或附件下载
			bigImg(file) {
				// console.log(file)
				//通过id获取图片或链接地址
				let url = file.url;
				this.dialogImageUrl = url;
				this.imgDialogVisible = true;
			},
			//上传图片中
			imgloading(event) {
				console.log(event)
				this.$message.danger("文件上传中请勿重复上传");
			},
			//富文本上传图片
			handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
				var formData = new FormData();
				formData.append("file", file);

				//上传图片
				upLoadFileObj(formData, {
					userid: this.GLOBAL.adminId()
				}).then(res => {
					if (res.result == 200) {
						let url = res.detail[0].path
						Editor.insertEmbed(cursorLocation, "image", url);
						let quill = this.$refs.quillEditor.quill;
						let index = quill.selection.savedRange.index;
						index += 1;
						quill.setSelection(index);
						resetUploader();
					} else {
						this.$message.error(res.description);
					}
				})
			},
			//商品画廊图片上传
			galleryChange(file, fileList) {
				let formData = new FormData()
				formData.append('file', file.raw);
				upLoadFileObj(formData, {
					userid: this.GLOBAL.adminId(),
					contentType: 'image/*'
				}).then((res) => {
					if (res.result == 200) {
						this.$message.success('图片上传成功')
						let url = res.detail[0].path;
						this.galleryList.push({
							name: res.detail[0].id,
							url
						});
						let imgs = [];
						this.galleryList.forEach(item => {
							imgs.push(item.url)
						})
						this.form.gallery = imgs.join(',');
					} else {
						this.$message.error('图片上传失败')
					}
				})
			},
			//商品画廊图片删除
			galleryRemove(file) {
				console.log(file)
				console.log(this.galleryList)
				this.galleryList.map((item, index) => {
					if (item.name == file.name) {
						this.galleryList.splice(index, 1)
					}
				})
			},
			//商品照片上传
			goodsfaceImgchange(file, fileList) {
				this.goodsfacehideUploadEidt = fileList.length > 0;
				let formData = new FormData()
				formData.append('file', file.raw);
				upLoadFileObj(formData, {
					userid: this.GLOBAL.adminId(),
					contentType: 'image/*'
				}).then((res) => {
					if (res.result == 200) {
						this.$message.success('图片上传成功')
						let url = res.detail[0].path;
						this.goodsfaceList.push({
							name: res.detail[0].id,
							url
						})
						this.goodsfacehideUploadEidt = true;
						this.form.pic_url = url;
					} else {
						this.$message.error('图片上传失败')
					}
				})
			},
			//商品照片删除
			goodsfaceImgRemove(file) {
				this.goodsfaceList.map((item, index) => {
					if (item.name == file.name) {
						this.goodsfaceList.splice(index, 1)
					}
				})
				this.form.pic_url = '';
				this.goodsfacehideUploadEidt = this.goodsfaceList.length > 0
			},
			submitForm(formName) {
				// this.$forceUpdate();
				this.$refs[formName].validate((valid) => {
					if (valid) {
						//多图片时
						let imgs = [];
						this.galleryList.forEach(item => {
							imgs.push(item.url)
						})
						this.form.gallery = JSON.stringify(imgs);
						let product = {
							"sn": this.form.sn,
							"name": this.form.name,
							"keywords": this.form.keywords,
							"intro": this.form.intro,
							"category_id": this.form.category_id,
							"shop_id": this.form.shop_id,
							"is_new": this.form.is_new ? 1 : 0,
							"is_hot": this.form.is_hot ? 1 : 0,
							"is_on_sale": this.form.is_on_sale ? 1 : 0,
							"sort": this.form.sort,
							"counter_price": this.form.counter_price,
							"retail_price": parseInt(this.form.retail_price),
							"payment_method": this.form.payment_method.join(','),
							"unit": this.form.unit,
							"detail": this.form.detail,
							"pic_url": this.form.pic_url,
							"gallery": this.form.gallery,
							"type": this.form.type,
							"verification_type": this.form.verification_type
						};
						let specifications = [{
							"name": '规格',
							"value": '标准'
						}];
						let goods = [{
							"specifications": "['标准']", //产品规格值数组
							"price": parseInt(this.form.retail_price), //价格
							"number": this.form.stockNum, //库存
							"payment_method": this.form.payment_method.join(','),
							"pic_url": '' //商品图片地址
						}];

						if (this.editType === 'update') {
							product.id = this.productId;
							goods = [{
								"id": this.goods[0].id,
								"specifications": "['标准']", //产品规格值数组
								"price": parseInt(this.form.retail_price), //价格
								"payment_method": this.form.payment_method.join(','),
								"number": this.form.stockNum, //库存
								"pic_url": '' //商品图片地址
							}];


							//如果当前商品类型是虚拟商品、核销类型为自动核销，需做如下判断
							if (this.form.verification_type == 1 && this.form.type == 1) {
								if (this.linkCodeRow.codesNumber < this.form.stockNum) {
									this.$message.warning('兑换码数量不能少于库存数，请先关联兑换码')
									return false;
								}
							}

							//修改
							sureMessageBox('是否确认修改商品信息？').then(res => {
								goodsUpdate({
									"product": product,
									"goods": goods,
								}).then(res => {
									if (res.result == 200) {
										this.$message.success('修改成功')
										this.getList();
										this.resetForm('form');
										this.dialogFormVisible = false;
									} else {
										this.$message.warning(res.description)
									}
								})
							}).catch(err => {
								console.log(err)
							})
						} else {
							//新增
							goodsCreate({
								"product": product,
								"specifications": specifications,
								"goods": goods,
							}).then(res => {
								if (res.result == 200) {
									this.$message.success('添加成功')
									this.currentPage = 1;
									this.getList();
									this.resetForm('form');
									this.dialogFormVisible = false;
								} else {
									this.$message.warning(res.description)
								}
							})
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			//关联兑换码
			linkCode(row) {
				// console.log(row);
				this.linkCodeRow = row;
				this.linkCodeDialogVisible = true;
				//获取未使用的兑换码列表
				getCodePage({
					shopId: row.shop_id,
					status: 1,
					pageSize: 99999,
					page: 1
				}).then(res => {
					// console.log(res)
					if (res.result == 200) {
						this.codesTableData = res.detail.list;
					} else {
						this.$message.warning(res.description)
					}
				})

				//获取已关联的兑换码
				getCodePage({
					shopId: row.shop_id,
					productId: this.linkCodeRow.goods[0].product_id,
					goodsId: this.linkCodeRow.goods[0].id,
					status: 2,
					pageSize: 99999,
					page: 1
				}).then(res => {
					// console.log(res)
					if (res.result == 200) {
						this.choosedCodes = res.detail.list;
						this.dealCodes();
					} else {
						this.$message.warning(res.description)
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.searchbox {
		margin: 20px 10px;
	}

	.imgbox_table {
		height: 80px;
		background-repeat: no-repeat;
		background-size: auto 100%;
		background-position: center;
	}

	.pagebox {
		margin: 20px 0;
		text-align: right;
	}

	.dialogForm ::v-deep .el-select-dropdown__item {
		padding: 0;
	}

	// 隐藏上传按钮
	::v-deep {

		.yyzzhide,
		.goodsfacehide,
		.hualanghide {
			&>div.el-upload--picture-card {
				display: none;
			}
		}
	}

	.codesbox {
		margin-top: 30px;

		>div {
			margin-bottom: 10px;
		}

		.el-tag {
			margin-right: 10px;
		}
	}
</style>
<style lang="scss">
	.editor-container .ql-container {
		height: 400px;
	}
</style>